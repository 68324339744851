import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions_station from "../../components/home-page/home-page-store/HomePageAction";
import * as actions from "../../components/home-page/home-page-store/HomePageAction";
import { Navbar, Nav,  } from "react-bootstrap";
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileLinks from "./navbar-links/navbar-links-mobile";
import LogoImage from "../../assets/images/icons/aa-logo-gray.png"
import strings from "../../resources/strings.json"
import "./navbar.scss";
import About from './about/About';
import ReleaseNotes from "../createUpdateRelease/release-notes";
import AdminMenu from "./navbar-links/navbar-admin";
import adminMenu from "../../assets/images/admin_menu.svg";
import { useHistory } from 'react-router-dom';

const NavigationBar = (props) => {
  
  const isAdmin = [strings.mm_admin,strings.mytime_admin, strings.mando_admin, strings.super, strings.mm_read_admin, strings.ot_read_admin, strings.mt_read_admin].includes(localStorage.getItem(strings.adminType));
  const [openAbout, setOpenAbout] = React.useState(false);
  const [showRelease, setShowRelease] = React.useState(false);
  const isMediaLarge = useMediaQuery('(min-width:600px)');
  const history = useHistory();
  const DividerStyleProps = {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
  }
//alphabetic sorting
  const mando = 
        [
            { title: "Dashboard",
                permission: [strings.mm_admin, strings.mm_read_admin, strings.ot_read_admin, strings.mando_admin,strings.super],
                nav: "/mando_RequestDetails"
            },
            { title: "History",
              permission: [strings.mm_admin,strings.mm_read_admin, strings.ot_read_admin, strings.mando_admin, strings.super],
              nav: "/mando_AllRequests"
            },
            { title: "Submit MOT Request",
                permission: [strings.mm_admin,strings.mando_admin, strings.super],
                nav:"/mando_details"
            },
            { title: "Union/DL Contacts",
                permission: [strings.mm_admin,strings.mando_admin,strings.super],
                nav: "/unionProfile"
            },
        ];

    const mytime =
        [
            { title: "Create Report",
                permission: [strings.mm_admin,strings.mytime_admin, strings.mm_read_admin, strings.mt_read_admin, strings.super],
                nav: "/absence-report"
            },
            { title: "Manage Reports",
                permission: [strings.super],
                nav: "/distro"
            },
            { title: "Recent Reports",
              permission: [strings.mm_admin,strings.mytime_admin, strings.mm_read_admin,strings.mt_read_admin, strings.super],
                nav: "/recent"
            },
            { title: "Submit Employee Request",
                permission: [strings.mm_admin,strings.mytime_admin, strings.super],
                nav: "/admin"
            },
            { title: "Super Admin Contacts",
              permission: [strings.mm_admin,strings.mytime_admin, strings.mm_read_admin,strings.mt_read_admin, strings.super],
                nav: "/admin-contacts"
            }, 
        ];

    const additionalMyTime = 
        [
            { title: "Create Report",
                permission: [strings.mm_admin,strings.mytime_admin, strings.mm_read_admin, strings.mt_read_admin, strings.super],
                nav: "/absence-report"
            },
            { title: "Create/Update Release",
              permission: [strings.mm_admin,strings.super],
              nav: "/create-update-Release"
            },
            { title: "Manage Abs Types",
                permission: [strings.mm_admin,strings.super],
                nav: "/manage-absence-types"
            },
            { title: "Manage Docs",
                permission: [strings.mm_admin,strings.super],
                nav: "/manage-documents"
            },
            { title: "Manage Reports",
                permission: [strings.super],
                nav: "/distro"
            },
            { title: "Recent Reports",
              permission: [strings.mm_admin,strings.mytime_admin, strings.mm_read_admin,strings.mt_read_admin, strings.super],
                nav: "/recent"
            },
            { title: "Station Access",
                permission: [strings.mm_admin,strings.super],
                nav: "/station-access"
            },
            { title: "Submit Employee Request",
                permission: [strings.mm_admin,strings.mytime_admin, strings.super],
                nav: "/admin"
            },
            { title: "Super Admin Contacts",
              permission: [strings.mm_admin,strings.mytime_admin, strings.mm_read_admin,strings.mt_read_admin, strings.super],
                nav: "/admin-contacts"
            }, 
        ];

  const [linkSet, setLinkSet] = useState();

  useEffect(()=>{
    const precomputedLinks = new Set([...mando, ...mytime].map(item => item.nav));
    precomputedLinks.add('/mando_ReportDetails');
      precomputedLinks.add('/mando_ReportConfirmation');
      precomputedLinks.add('/mandoConfirmation');
      precomputedLinks.add('/mando_extendConfirmation');
      precomputedLinks.add('/mando_releaseConfirmation');
    setLinkSet(precomputedLinks);
  },[])

  const handleClick = () => {
    props.setisAdminMenuOpen(!props.isAdminMenuOpen)
  }

  useEffect(()=> {
    let precomputedLinks
    if (!linkSet) {
      precomputedLinks = new Set([...mando, ...mytime].map(item => item.nav));
      precomputedLinks.add('/mando_ReportDetails');
      precomputedLinks.add('/mando_ReportConfirmation');
      precomputedLinks.add('/mandoConfirmation');
      precomputedLinks.add('/mando_extendConfirmation');
      precomputedLinks.add('/mando_releaseConfirmation');
      setLinkSet(precomputedLinks);
    }
    if (history.location.pathname == "/myTimeDashboard") props.navTreeReset();
    const unlisten = history.listen((location) => {
        if (linkSet && !linkSet.has(location.pathname) || precomputedLinks && !precomputedLinks.has(location.pathname)) {
          props.navTreeReset();
        };
    });
  },[history])

  const handleClickNavigation = (e, currentIdx) => {
    let idx = props.navTree.indexOf(e.currentTarget.id);
    if (idx == props.navTree.length -1) {
        return;
    }
    let backToIdx;
    props.action_station.navTreeGoBack(e.currentTarget.id);
    if ( idx >= 0 ) {
      backToIdx= (props.navTree.length - idx )- 1;
    }
    for (let i = 0; i < backToIdx; i++) {
        history.goBack()
    }
    return;
}

  return (
    <>
        <nav>
          <div className="nav-header-container">
            <div className="nav-header-title" onClick={handleClick}>
              {isAdmin &&(
              props.isAdminMenuOpen ? 
             <p  style={{fontSize: '20px', lineHeight: '18px', cursor: 'pointer', margin: 0}}> X </p> :
             <img src={adminMenu} />) }
            </div>
            <Nav>
              { ![strings.normal, null, ""].includes(localStorage.getItem(strings.adminType)) &&
                
              <NavLink onClick={()=>props.navTreeReset()}
                    to="/set_HomePage"
                    >
                      <div className="nav-header-title help-feedback">
                        Set Home Page
                      </div>
              </NavLink> }
              { ![strings.normal, null, ""].includes(localStorage.getItem(strings.adminType)) &&
              <span style={{ color: "#122C34", fontSize: 13 }}>&nbsp;|&nbsp;</span> }
              <NavLink onClick={()=>props.navTreeReset()}
                    to="/faq"
                    >
                      <div className="nav-header-title help-feedback">
                        FAQ
                      </div>
              </NavLink>
              <span style={{ color: "#122C34", fontSize: 13 }}>&nbsp;|&nbsp;</span>
              <NavLink onClick={()=>props.navTreeReset()}
                    to="/feedBack"
                    >
                      <div className="nav-header-title help-feedback">
                      Feedback
                      </div>
              </NavLink>
            </Nav>
          </div>
        </nav>
      <nav className="navbar-items nav-bar"> 
        <div className={isMediaLarge ? "logo-container" : "logo-container logo-alignment"}>
          <a href="/myTimeDashboard">
            <img src={LogoImage} alt="logo" className="logo-image" />
            <h1 className="logo-text">{strings.myTime}</h1>
          </a>
        </div>
        <MobileLinks isAdminMenuOpen={props.isAdminMenuOpen} setisAdminMenuOpen={(state)=>props.setisAdminMenuOpen(state)} setOpenAbout={setOpenAbout} setShowRelease={setShowRelease}/>
      </nav>
      {(isAdmin && props.additionalAccess && props.isAdminMenuOpen)
      ? <AdminMenu
          navMenu = {{mando,additionalMyTime}}
        />
      : (isAdmin && props.isAdminMenuOpen) 
        && <AdminMenu
             navMenu = {{mando,mytime}}
           />
      }
      {(!props.isAdminMenuOpen)&&
      <div className='divider'>
        <Divider style={DividerStyleProps}></Divider>
      </div>}
      {isAdmin &&
        <div style={{display: 'flex'}} className="navigation-tree">
            {props.navTree&&props.navTree.map((it, i)=>{
              return <div onClick={(e)=>handleClickNavigation(e, i)} id={it} key={(i+it.nav).toString()}>&gt; {it}</div>
            })}
        </div>}
      {openAbout ? <About open={openAbout} handleClose={() => setOpenAbout(false)} /> : null}
      {showRelease?  <ReleaseNotes open={showRelease} handelClose={() => 
        setShowRelease(false)
        } /> : null}
    </>
  )
}

const mapStateToProps = (state) => ({
  isAdminMenuOpen: state.HomePageReducer.isAdminMenuOpen,
  additionalAccess: state.HomePageReducer.additionalAccess,
  navTree: state.HomePageReducer.navTree,
 })

const mapDispatchToProps = (dispatch) => ({
  action_station: bindActionCreators({...actions_station}, dispatch),
  navTreeReset: (params) =>
    dispatch({ type: "NAV_TREE_RESET", params:params }),
  setisAdminMenuOpen: (params) =>
    dispatch({ type: "SET_ADMIN_MENU_OPEN", params:params }),
});

export default connect(mapStateToProps,mapDispatchToProps)(NavigationBar);
